import { OffersPages } from 'types/pages';

export enum ViewType {
  table = 'table',
  cards = 'cards'
}

export type OfferView = Record<OffersPages, ViewType>;

export interface ConrolPanelState {
  offerView: OfferView;
}