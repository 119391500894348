import { ViewType } from './types';

export const getInitialState = () => {
  return {
    offerView: {
      solutions: ViewType.cards,
      data: ViewType.cards,
      compute: ViewType.table,
      storage: ViewType.table,
    },
  };
};