import {
  createTransform,
} from 'redux-persist';
import { Modes } from 'uikit/MnemonicGenerator/MnemonicGeneratorUi/types';
import {
  CreateOrderState,
  Steps,
  FieldsCreatePassphraseForm,
} from './types';

export const transform = createTransform(
  (inboundState: CreateOrderState) => {
    return {
      ...inboundState,
      [Steps.CREATE_PASSPHRASE]: { [FieldsCreatePassphraseForm.phraseMode]: Modes.own },
      [Steps.BUILD_ORDER]: {
        ...inboundState?.[Steps.BUILD_ORDER],
        file: null,
        configurationError: null,
      },
    };
  },
  (outboundState: CreateOrderState) => {
    return {
      ...outboundState,
      [Steps.CREATE_PASSPHRASE]: { [FieldsCreatePassphraseForm.phraseMode]: Modes.own },
      [Steps.BUILD_ORDER]: {
        ...outboundState?.[Steps.BUILD_ORDER],
        file: null,
        configurationError: null,
      },
    };
  },
  { whitelist: ['form'] },
);