import {
  AnyAction,
  createSlice, PayloadAction,
} from '@reduxjs/toolkit';

import {
  getResult, replenishDeposit, cancelOrder,
} from './thunks';
import type { OrderDetailsState } from './types';

import { isError } from '../helpers';

const initialState: OrderDetailsState = {};

export const orderDetails = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getResult.pending, (state, { meta }) => {
        const { orderId } = meta.arg;
        state[orderId] = {
          loading: true,
          operation: 'getResult',
        };
      })
      .addCase(getResult.fulfilled, (state, action: PayloadAction<{ isFile: boolean, content: string, orderId: string }>) => {
        const { isFile, content, orderId } = action.payload || {};
        state[orderId] = {
          loading: false,
          operation: 'getResult',
          isFile,
          content,
        };
      })
      .addCase(replenishDeposit.pending, (state, { meta }) => {
        const { orderId } = meta.arg;
        state[orderId] = {
          loading: true,
          operation: 'replenishDeposit',
        };
      })
      .addCase(replenishDeposit.fulfilled, (state, action: PayloadAction<string>) => {
        state[action.payload] = {
          loading: false,
        };
      })
      .addCase(cancelOrder.pending, (state, { meta }) => {
        const { orderId } = meta.arg;
        state[orderId] = {
          loading: true,
          operation: 'cancelOrder',
        };
      })
      .addCase(cancelOrder.fulfilled, (state, action: PayloadAction<string>) => {
        state[action.payload] = {
          loading: false,
        };
      })
      .addMatcher(isError, (state, action: AnyAction) => {
        const { orderId } = action?.meta?.arg || {};
        state[orderId] = {
          loading: false,
          isFile: undefined,
          content: undefined,
          operation: undefined,
        };
      });
  },
  selectors: {
    orderDetailsStateSelector: (state) => state,
  },
});

export const {
  orderDetailsStateSelector,
} = orderDetails.selectors;
