import {
  TeeOffer, Offer,
} from 'generated/types';
import { OfferType } from '@super-protocol/sdk-js';
import { Modes } from 'uikit/MnemonicGenerator/MnemonicGeneratorUi/types';

export enum Steps {
  BUILD_ORDER = 'build_order',
  CREATE_PASSPHRASE = 'create_passphrase',
  CONFIRM_TRANSACTIONS = 'confirm_transactions',
}

export enum Status {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  COMPLETED = 'completed',
}

export interface Item {
  value: Steps;
  status: Status;
}

export interface Slot {
  id: string,
  count: number, // only required for TEE offers. Value Offers must be 0 if id is present
}

export interface Option {
  id: string;
  count: number;
}

export interface Slots {
  slot?: Slot | null;
  options?: Option[] | null;
}

export interface FormOffer {
  value?: string;
  data?: Offer | TeeOffer | null,
  slots?: Slots | null,
  base?: FormOffer[];
}

export enum FieldsBuildOrderForm {
  solution = 'solution',
  data = 'data',
  tee = 'tee',
  storage = 'storage',
  file = 'file',
  deposit = 'deposit',
  configurationError = 'configurationError',
}

export type FormOfferKey = FieldsBuildOrderForm.solution
 | FieldsBuildOrderForm.data
 | FieldsBuildOrderForm.tee
 | FieldsBuildOrderForm.storage;

export interface FormOffers {
  [FieldsBuildOrderForm.solution]: FormOffer | null;
  [FieldsBuildOrderForm.tee]: FormOffer | null;
  [FieldsBuildOrderForm.storage]: FormOffer | null;
  [FieldsBuildOrderForm.data]: FormOffer[];
}

export interface BuildOrderForm extends FormOffers {
  [FieldsBuildOrderForm.file]?: string | null; // file id
  [FieldsBuildOrderForm.deposit]: string; // wei
  [FieldsBuildOrderForm.configurationError]?: string;
}

export enum FieldsCreatePassphraseForm {
  phraseGenerated = 'phraseGenerated',
  phraseInput = 'phraseInput',
  phraseMode = 'phraseMode',
  agreement = 'agreement',
}

export interface CreatePassphraseForm {
  [FieldsCreatePassphraseForm.phraseGenerated]?: string;
  [FieldsCreatePassphraseForm.phraseInput]?: string;
  [FieldsCreatePassphraseForm.phraseMode]?: Modes;
  [FieldsCreatePassphraseForm.agreement]?: boolean;
}

export interface ConfirmTransactionsForm {}

export interface Form {
  [Steps.BUILD_ORDER]: BuildOrderForm;
  [Steps.CREATE_PASSPHRASE]: CreatePassphraseForm;
  [Steps.CONFIRM_TRANSACTIONS]: ConfirmTransactionsForm;
}

export enum ProcessType {
  FILE = 'FILE',
  APPROVE = 'APPROVE',
  WORKFLOW = 'WORKFLOW'
}

export enum ProcessStatus {
  QUEUE = 'QUEUE',
  PROGRESS = 'PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR',
  QUEUE_HIDE = 'QUEUE_HIDE',
  DONE_HIDE = 'DONE_HIDE',
}

export interface ProcessValue {
  status: ProcessStatus;
  error?: Error;
}

export interface Process {
  [process: string]: ProcessValue;
}
export interface CreateOrderState {
  steps: Item[];
  form: Form;
  process: Process;
  submitLoading: boolean;
}

export interface CheckAddOffer {
  isAdded: boolean;
}

export interface CheckAddOfferArgs {
  id: string;
  field: FieldsBuildOrderForm;
}

export interface GetExtendedFormOffersProps {
  formOffers?: FormOffers;
  offers?: (Offer | undefined | null)[];
  teeOffers: (TeeOffer | undefined | null)[];
}

export interface CheckConflictResult {
  [FieldsBuildOrderForm.solution]?: FormOffer | null;
  [FieldsBuildOrderForm.tee]?: FormOffer | null;
  [FieldsBuildOrderForm.storage]?: FormOffer | null;
  [FieldsBuildOrderForm.data]?: FormOffer[];
}

export interface IsDataInFormOffersResult {
  [FieldsBuildOrderForm.solution]?: boolean;
  [FieldsBuildOrderForm.tee]?: boolean;
  [FieldsBuildOrderForm.storage]?: boolean;
  [FieldsBuildOrderForm.data]?: boolean;
}

export interface ErrorIsDataInFormOffers {
  [FieldsBuildOrderForm.solution]?: string;
  [FieldsBuildOrderForm.tee]?: string;
  [FieldsBuildOrderForm.storage]?: string;
  [FieldsBuildOrderForm.data]?: string;
}

export interface OfferRestrictionWithType {
  offer: string;
  type: OfferType | null;
}