import cloneDeep from 'lodash.clonedeep';
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { getBroatcastActions } from 'lib/features/helpers';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getInitialSteps, getInitialState } from './helpers';
import { transform } from './persist';
import migrations from './migrations';
import {
  CreateOrderState,
  FormOffer,
  Steps,
  FormOfferKey,
  BuildOrderForm,
  Item,
  Status,
  CreatePassphraseForm,
  ConfirmTransactionsForm,
  Process,
  ProcessValue,
  ProcessType,
  Slot,
} from './types';
import { Storage } from '../types';

const initialState: CreateOrderState = getInitialState();

export const createOrder = createSlice({
  name: 'createOrder',
  initialState,
  reducers: {
    reset() {
      return getInitialState();
    },
    updateProcesses(state, action: PayloadAction<Process>) {
      state.process = action.payload;
    },
    updateProcess(state, action: PayloadAction<{ process: ProcessType, value: ProcessValue }>) {
      const { process, value } = action.payload || {};
      state.process = { ...state.process, [process]: value };
    },
    resetSteps(state) {
      state.steps = getInitialSteps();
    },
    updateSteps(state, action: PayloadAction<Item[]>) {
      state.steps = action.payload;
    },
    goNextStep(state) {
      const activeIndex = state.steps.findIndex((step) => step.status === Status.ACTIVE);
      if (activeIndex + 1 <= state.steps.length) {
        state.steps = state.steps.map((step, index) => {
          if (index === activeIndex) {
            return {
              ...step,
              status: Status.COMPLETED,
            };
          }
          if (index === activeIndex + 1) {
            return {
              ...step,
              status: Status.ACTIVE,
            };
          }
          return step;
        });
      }
    },
    goBackStep(state) {
      const activeIndex = state.steps.findIndex((step) => step.status === Status.ACTIVE);
      if (activeIndex - 1 >= 0) {
        state.steps = state.steps.map((step, index) => {
          if (index === activeIndex) {
            return {
              ...step,
              status: Status.DISABLED,
            };
          }
          if (index === activeIndex - 1) {
            return {
              ...step,
              status: Status.ACTIVE,
            };
          }
          return step;
        });
      }
    },
    updateFormByStep(
      state,
      action: PayloadAction<{
         step: Steps, values: BuildOrderForm | CreatePassphraseForm | ConfirmTransactionsForm, exclude: string[]
      }>,
    ) {
      const { step, values, exclude = [] } = action.payload;
      if (JSON.stringify(values) !== JSON.stringify(state.form[step])) {
        const newValues = cloneDeep(values);
        exclude.forEach((key) => delete newValues[key]);
        state.form[step] = newValues;
      }
    },
    addOfferToForm(state, action: PayloadAction<{ key?: FormOfferKey, value: FormOffer | FormOffer[] }>) {
      const { key, value } = action.payload || {};
      if (!key) return;
      if (Array.isArray(state.form[Steps.BUILD_ORDER][key])) {
        (state.form[Steps.BUILD_ORDER][key] as FormOffer[]) = Array.isArray(value) ? value : [value];
      } else {
        (state.form[Steps.BUILD_ORDER][key] as FormOffer) = Array.isArray(value) ? value[0] : value;
      }
    },
    updateOfferInForm(state, action: PayloadAction<{ key?: FormOfferKey, value: FormOffer | null | FormOffer[] }>) {
      const { key, value } = action.payload || {};
      if (!key || !value) return;
      if (Array.isArray(state.form[Steps.BUILD_ORDER][key])) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            const index = (state.form[Steps.BUILD_ORDER][key] as FormOffer[]).findIndex(({ value }) => value === v?.value);
            if (index === -1) {
              (state.form[Steps.BUILD_ORDER][key] as FormOffer[]).push(v);
            } else {
              (state.form[Steps.BUILD_ORDER][key] as FormOffer[])[index] = v;
            }
          });
        } else {
          const index = (state.form[Steps.BUILD_ORDER][key] as FormOffer[])
            .findIndex(({ value: offerId }) => offerId === value?.value);
          if (index === -1) {
            (state.form[Steps.BUILD_ORDER][key] as FormOffer[]).push(value);
          } else {
            (state.form[Steps.BUILD_ORDER][key] as FormOffer[])[index] = value;
          }
        }
      } else {
        (state.form[Steps.BUILD_ORDER][key] as FormOffer) = Array.isArray(value) ? value[0] : value;
      }
    },
    updateSlotInForm(state, action: PayloadAction<{ key?: FormOfferKey; value: Slot | null; offerId?: string; }>) {
      const { key, value, offerId } = action.payload || {};
      if (!key || !offerId) return;
      if (Array.isArray(state.form[Steps.BUILD_ORDER][key])) {
        const index = (state.form[Steps.BUILD_ORDER][key] as FormOffer[]).findIndex(({ value }) => value === offerId);
        if (index !== -1) {
          const oldValue = (state.form[Steps.BUILD_ORDER][key] as FormOffer[])[index];
          (state.form[Steps.BUILD_ORDER][key] as FormOffer[])[index] = {
            ...oldValue,
            slots: {
              ...oldValue?.slots,
              slot: value,
            },
          };
        }
      } else {
        const oldValue = state.form[Steps.BUILD_ORDER][key] as FormOffer;
        (state.form[Steps.BUILD_ORDER][key] as FormOffer) = {
          ...oldValue,
          slots: {
            ...oldValue?.slots,
            slot: value,
          },
        };
      }
    },
    deleteSlotInForm(state, action: PayloadAction<{ key?: FormOfferKey; offerId?: string; }>) {
      const { key, offerId } = action.payload || {};
      if (!key || !offerId) return;
      if (Array.isArray(state.form[Steps.BUILD_ORDER][key])) {
        const index = (state.form[Steps.BUILD_ORDER][key] as FormOffer[]).findIndex(({ value }) => value === offerId);
        if (index !== -1) {
          const oldValue = (state.form[Steps.BUILD_ORDER][key] as FormOffer[])[index];
          (state.form[Steps.BUILD_ORDER][key] as FormOffer[])[index] = {
            ...oldValue,
            slots: {
              ...oldValue?.slots,
              slot: null,
            },
          };
        }
      } else {
        const oldValue = state.form[Steps.BUILD_ORDER][key] as FormOffer;
        (state.form[Steps.BUILD_ORDER][key] as FormOffer) = {
          ...oldValue,
          slots: {
            ...oldValue?.slots,
            slot: null,
          },
        };
      }
    },
    // todo maybe later
    // updateOptionInForm(state, action: PayloadAction<{ key?: FormOfferKey; value: [string, number] | null; offerId?: string; }>) {
    //   const { key, value, offerId } = action.payload || {};
    //   if (!key || !offerId || !value) return;
    //   if (Array.isArray(state.form[Steps.BUILD_ORDER][key])) {
    //     const index = (state.form[Steps.BUILD_ORDER][key] as FormOffer[]).findIndex(({ value }) => value === offerId);
    //     if (index !== -1) {
    //       const oldValue = (state.form[Steps.BUILD_ORDER][key] as FormOffer[])[index];
    //       (state.form[Steps.BUILD_ORDER][key] as FormOffer[])[index] = {
    //         ...oldValue,
    //         slots: {
    //           ...oldValue?.slots,
    //           options: [...(oldValue?.slots?.options || []), { id: value[0], count: value[1] }],
    //         },
    //       };
    //     }
    //   } else {
    //     const oldValue = state.form[Steps.BUILD_ORDER][key] as FormOffer;
    //     (state.form[Steps.BUILD_ORDER][key] as FormOffer) = {
    //       ...oldValue,
    //       slots: {
    //         ...oldValue?.slots,
    //         options: [...(oldValue?.slots?.options || []), { id: value[0], count: value[1] }],
    //       },
    //     };
    //   }
    // },
    // when there are many abstractions =/
    deleteOfferFromForm(state, action: PayloadAction<{ key?: FormOfferKey, value?: string | string[] }>) {
      const { key, value } = action.payload || {};
      if (!key) return;
      // todo add a base offer checking in array offers (if required)
      if (Array.isArray(state.form[Steps.BUILD_ORDER][key])) {
        (state.form[Steps.BUILD_ORDER][key] as FormOffer[]) = (
          state.form[Steps.BUILD_ORDER][key] as FormOffer[]
        )?.filter((item) => {
          if (Array.isArray(value)) {
            return !value.length || !value.includes(item?.value as string);
          }
          return item?.value !== value;
        });
        // if there is no value or remove the base offer - drop the full state by field
      } else if (
        !value
        || ((state.form[Steps.BUILD_ORDER][key] as FormOffer)?.base || [])?.some((formOffer) => formOffer?.value === value)
      ) {
        state.form[Steps.BUILD_ORDER][key] = null as never;
        // if the value is the main offer - try to replace with the base offer or drop
      } else if ((state.form[Steps.BUILD_ORDER][key] as FormOffer)?.value === value) {
        state.form[Steps.BUILD_ORDER][key] = null as never;
      }
    },
    updateDeposit(state, action: PayloadAction<string>) {
      state.form[Steps.BUILD_ORDER].deposit = action.payload;
    },
    updateSubmitLoading(state, action: PayloadAction<boolean>) {
      state.submitLoading = action.payload;
    },
  },
  selectors: {
    stepsSelector: (state) => state.steps,
    formSelector: (state) => state.form,
    processSelector: (state) => state.process,
    submitLoadingSelector: (state) => state.submitLoading,
  },
});

export const {
  resetSteps,
  addOfferToForm,
  updateOfferInForm,
  deleteOfferFromForm,
  updateFormByStep,
  updateSteps,
  goNextStep,
  goBackStep,
  updateProcess,
  updateProcesses,
  updateDeposit,
  updateSubmitLoading,
  reset,
  updateSlotInForm,
  deleteSlotInForm,
  // updateOptionInForm,
  // deleteOptionInForm,
} = createOrder.actions;

export const {
  stepsSelector,
  formSelector,
  processSelector,
  submitLoadingSelector,
} = createOrder.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'createOrder', storage, whitelist: ['form'], transforms: [transform], migrate: migrations, version: 0,
}, createOrder.reducer);

export const getBroatcastWhiteList = () => getBroatcastActions(createOrder);