import { createMigrate } from 'redux-persist';
import { getInitialState } from './helpers';

const resetState = (state) => ({
  ...state,
  ...getInitialState(),
});

const migrations = {
  0: resetState,
  1: resetState,
  2: resetState,
  3: resetState,
};

export default createMigrate(migrations);