import { Sorting } from 'components/Main/Content/ControlPanel/Sorting/types';
import { OffersPages } from 'types/pages';
import { ControlPanelFields } from 'components/Main/Content/ControlPanel/types';
import { OfferFilter, ConnectionArgs, TOfferType } from 'generated/types';

export interface Variables { filter: OfferFilter, pagination: ConnectionArgs }

export enum FiltersFields {
  providers = 'providers',
  offerType = 'offerType',
  slots = 'slots',
  options = 'options',
  lease = 'lease',
  availability = 'availability',
  compatible = 'compatible',
  offers = 'offers',
}

export enum Compatible {
  all = 'all',
  compatible = 'compatible'
}

export enum Offers {
  sp = 'sp',
  approved = 'approved',
  unmoderated = 'unmoderated',
  inactive = 'inactive'
}

export enum Blocks {
  filters = 'filters',
  controlPanel = 'controlPanel'
}
export interface FormControlPanel {
  [ControlPanelFields.sorting]?: Sorting;
  [ControlPanelFields.size]?: number | null;
}

export enum Availability {
  any = 'any',
  now = 'now',
}

export enum ExtPorts {
  any = 'any',
  yes = 'yes',
}

export interface Slots {
  cpuCores?: number | null;
  ram?: number | null;
  diskUsage?: number | null;
}

export interface Options {
  bandwidth?: number | null;
  traffic?: number | null;
  externalPort?: ExtPorts | null;
}

export interface Lease {
  time?: number | null;
}
export interface FormFiltersSolutionsCommon {
  [FiltersFields.offerType]?: TOfferType;
}

export interface FormFiltersSolutions extends FormFiltersSolutionsCommon {
  [FiltersFields.providers]?: string[];
  [FiltersFields.compatible]?: Compatible;
  [FiltersFields.offers]?: Offers;
}

export interface FormFiltersData extends FormFiltersSolutionsCommon {
  [FiltersFields.providers]?: string[];
  [FiltersFields.compatible]?: Compatible;
  [FiltersFields.offers]?: Offers;
}

export interface FormFiltersStorage extends FormFiltersSolutionsCommon {
  [FiltersFields.providers]?: string[];
  [FiltersFields.offers]?: Offers;
}

export interface FormFiltersCompute extends FormFiltersSolutionsCommon {
  [FiltersFields.slots]?: Slots;
  [FiltersFields.options]?: Options;
  [FiltersFields.lease]?: Lease;
  [FiltersFields.availability]?: Availability;
}

export type FormFilters = FormFiltersSolutions | FormFiltersData | FormFiltersStorage | FormFiltersCompute

export interface Form {
  [Blocks.filters]?: FormFilters | null;
  [Blocks.controlPanel]?: FormControlPanel | null;
}

export interface FiltersState {
  [OffersPages.compute]: Form;
  [OffersPages.data]: Form;
  [OffersPages.solutions]: Form;
  [OffersPages.storage]: Form;
}

export interface GetResetDataProps {
  block?: Blocks;
  active: OffersPages;
}