import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getBroatcastActions } from 'lib/features/helpers';
import { ViewType, ConrolPanelState, OfferView } from './types';
import { Storage } from '../types';
import { getInitialState } from './helpers';
import migrations from './migrations';

const initialState: ConrolPanelState = getInitialState();

export const controlPanel = createSlice({
  name: 'controlPanel',
  initialState,
  reducers: {
    updateOffersViewPage(state, action: PayloadAction<{ active: string, viewType: ViewType }>) {
      state.offerView = { ...state.offerView, [action.payload.active]: action.payload.viewType };
    },
    updateOffersView(state, action: PayloadAction<OfferView>) {
      state.offerView = action.payload;
    },
  },
  selectors: {
    offerViewContolPanelSelector: (state) => (state.offerView),
  },
});

export const { updateOffersViewPage, updateOffersView } = controlPanel.actions;

export const { offerViewContolPanelSelector } = controlPanel.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'controlPanel', storage, whitelist: ['offerView'], migrate: migrations, version: 0,
}, controlPanel.reducer);

export const getBroatcastWhiteList = () => getBroatcastActions(controlPanel);