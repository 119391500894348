import { OffersPages } from 'types/pages';

export interface SortingProps {
  name: string;
  onSubmit: () => void;
  className?: string;
  interval?: number;
  page?: OffersPages;
}

export enum Sorting {
  newest = 'newest',
  price = 'price',
  availability = 'availability',
}