import { createMigrate } from 'redux-persist';
import { getInitialState as getInitialStateCreateOrder } from './helpers';

const migrations = {
  0: (state) => ({
    ...state,
    ...getInitialStateCreateOrder(),
  }),
};

export default createMigrate(migrations);